import { IPageEvent, PageEventType } from "core/events/page";
import sequentialMenu from '../core/components/sequentialMenu';
import * as pageScroll from "../core/pageScroll";
import { breakpointUp, breakpointSize } from '../core/utils/css';

const $pageWrapper = $('.page');
const $pageHeader = $('.page-header');
const $pageHeaderBar = $pageHeader.find('.page-header-bar');
const $sequentialMenu = $pageHeader.find('[data-component="sequential-menu"]');
const $togglerBtn = $pageHeader.find('.toggler-btn');
const $contentSlotWrapper = $pageHeader.find('.page-header-content');
const $consentCookieTracking = $pageHeader.find('.consent-cookie-tracking');
const $mobileSideBar = $pageHeader.find('.mobile-side-bar');
const $formLogout = $pageHeader.find('#logout_form');
const $menuMainContainer = $pageHeader.find('ul.main-container');
const isLargeViewport = breakpointUp(breakpointSize.LG);

let isMobileSideBarOpened = false;
let minHeightSideBar: number|undefined;
let isNestedCategoriesLoaded = false;
let savedScrollPosition = 0;

function openMobileSideBar() {
    loadMenu();

    isMobileSideBarOpened = true;

    pageScroll.stop();
    savedScrollPosition = Math.max(0, scrollY);
    scrollTo({ top: 0, left: 0, behavior: 'instant' });

    $mobileSideBar.show();
    $contentSlotWrapper.hide();
    $consentCookieTracking.hide();
    $pageWrapper.hide();
    $menuMainContainer.addClass('open');

    // Set initial height as min-height
    if (!minHeightSideBar) {
        minHeightSideBar = $mobileSideBar.height();
        $mobileSideBar.css('min-height', minHeightSideBar + 'px');
    }

    $togglerBtn.find('.icon-menu').hide();
    $togglerBtn.find('.icon-close').show();

    isLargeViewport.addEventListener('change', onResize);
}

function closeMobileSideBar() {
    isMobileSideBarOpened = false;

    $mobileSideBar.hide();
    $contentSlotWrapper.show();
    $consentCookieTracking.show();
    $pageWrapper.show();

    $sequentialMenu.trigger('sequential-menu:reset');

    $togglerBtn.find('.icon-menu').show();
    $togglerBtn.find('.icon-close').hide();

    isLargeViewport.removeEventListener('change', onResize);

    scrollTo({ top: savedScrollPosition, left: 0, behavior: 'instant' });
    pageScroll.start();
}

function onClickTogglerBtn() {
    if (isMobileSideBarOpened) closeMobileSideBar();
    else openMobileSideBar();
}

function onNavigateSequentialMenu(_event: any, data: any) {
    scrollTo({ top: 0, left: 0, behavior: 'instant' });
    $pageWrapper.css('height', $pageHeader.height() + data.height);
}

function onResize(ev: MediaQueryListEvent) {
    if (ev.matches) closeMobileSideBar();
}

function onUpdatePageScroll(event: CustomEvent<IPageEvent>) {
    if (event.detail.diffDirection && $pageHeaderBar) {
        const height = $pageHeaderBar.height() ?? 0;

        if (event.detail.scrollY > height) {
            if (event.detail.direction === 'down') {
                $pageHeaderBar.addClass('slideout');
                $pageHeaderBar.removeClass('slidein');

                setTimeout(() => { $pageHeader.removeClass('page-header--sticky'); }, 150);
            } else if (event.detail.direction === 'up') {
                $pageHeaderBar.addClass('slidein');
                $pageHeaderBar.removeClass('slideout');
                $pageHeader.addClass('page-header--sticky');
            }
        } else {
            $pageHeaderBar.removeClass('slidein');
            $pageHeaderBar.removeClass('slideout');
            $pageHeader.removeClass('page-header--sticky');
        }
    }
}

function onLogout(event: JQuery.MouseEventBase): boolean {
    event.preventDefault();
    $formLogout.trigger('submit');
    return false;
}

/**
 * load sequential menu items from the menu controller into the DOM
 */
function loadMenu() {
    if (!isNestedCategoriesLoaded) {

        $.ajax({
            url: $sequentialMenu.data('url'),
            method: 'GET',
            success: (responseData) => {
                $menuMainContainer.find('.sequential-menu-item').remove();
                $menuMainContainer.append(responseData);
            }
        }).always(() => {
            // Add event listeners even if level 2+ categories cannot be returned.
            $sequentialMenu.each((_i, menuEl) => sequentialMenu(menuEl));
        })

        isNestedCategoriesLoaded = true;
    }
}

addEventListener(PageEventType.SCROLL, onUpdatePageScroll, { passive: true });

$togglerBtn.on('click', onClickTogglerBtn);
$sequentialMenu.on('sequential-menu:navigate', onNavigateSequentialMenu);
$('body').on('click', '.user-submit-logout', onLogout);
