import { AddToCartEventType } from 'core/events/addToCart';
import { hideSpinner, showSpinner } from 'framework/core/ui/components/spinner/spinner';
import { getURL } from '../../core/utils/url';
import ButtonWrapper from '../../core/components/buttonWrapper';
import { handleServerErrors } from '../../core/components/request';

/**
 * Add to cart
 * Wrap a button or use it as a button and build the "add to cart" functionality for it
 * @see https://thewarehouse.atlassian.net/wiki/spaces/GEP/pages/1948386152/Design+System+Components+AddToCart
 */
export class AddToCart extends ButtonWrapper {
    static get observedAttributes() {
        return ['pid', 'quantity', 'disabled'];
    }

    action: string | undefined;
    productContainer: any;

    request() {
        showSpinner();

        $.ajax({
            url: this.action,
            method: 'POST',
            dataType: 'json',
            data: {
                pid: this.pid,
                quantity: this.quantity,
                context: this.context,
                options: []
            },
            success: (response) => {
                hideSpinner();

                const detail = {
                    pid: this.pid,
                    quantity: this.quantity,
                    context: this.context,
                    ...response
                };

                if (response.error) {
                    $.notification({
                        type: $.NOTIFICATION_TYPE.DANGER,
                        time: $.NOTIFICATION_TIME.SHORT,
                        message: response.message
                    }).show();
                } else {
                    this.dispatchEvent(new CustomEvent(AddToCartEventType.ADDED_TO_CART, {
                        bubbles: true,
                        detail
                    }));

                    $.notification({
                        type: $.NOTIFICATION_TYPE.SUCCESS,
                        time: $.NOTIFICATION_TIME.SHORT,
                        message: response.message
                    }).show();

                    window.dispatchEvent(new CustomEvent('minicart:update', { detail: { quantityTotal: response.quantityTotal } }));
                }
            },
            error: (err) => {
                hideSpinner();
                if (handleServerErrors(err)) return;

                const message = err.responseJSON ? err.responseJSON.errorMessage : $.NOTIFICATION_GLOBAL_MESSAGE.ERROR;

                $.notification({
                    type: $.NOTIFICATION_TYPE.DANGER,
                    time: $.NOTIFICATION_TIME.SHORT,
                    message
                }).show();
            }
        });
    }

    /**
     * onClick
     * @param {MouseEvent} event - mouse click
     */
    onClick(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.request();
    }

    connectedCallback() {
        super.connectedCallback();
        this.action = getURL('cart-add-product');
        this.productContainer = this.closest('.product-detail');
    }

    attributeChangedCallback(name: string): void {
        if (name === 'disabled' && this.button) {
            this.button.classList.toggle('disabled', this.disabled)
        }
    }

    /**
     * get quantity
     */
    get quantity() {
        return this.getAttribute('quantity') ?? '1';
    }

    /**
     * set quantity
     * @param {string} value - quantity value - default 1
     */
    set quantity(value: string) {
        this.setAttribute('quantity', value);
    }

    /**
     * get context
     */
    get context() {
        return this.getAttribute('context') ?? '1';
    }

    /**
     * set context
     * @param {string} value - context value - default 1
     */
    set context(value: string) {
        this.setAttribute('context', value);
    }

    get disabled() {
        return this.hasAttribute('disabled');
      }

    set disabled(val) {
        if (val) {
            this.setAttribute('disabled', '');
        } else {
            this.removeAttribute('disabled');
        }
    }
}

window.customElements.define('gep-add-to-cart', AddToCart);

declare global {
    interface HTMLElementTagNameMap {
        'gep-add-to-cart': AddToCart
    }
}
