import { hideSpinner, showSpinner } from 'framework/core/ui/components/spinner/spinner';
import { getURL } from '../../core/utils/url';
import { handleServerErrors } from '../../core/components/request';

/**
 * Add to wishlist
 * this component adds a product to wishlist and also indicates if the product is already in the wishlist
 */
export default class AddToWishlist extends HTMLElement {
    static get observedAttributes() {
        return ['pid'];
    }

    wishlistData: HTMLElement;
    wishlistItems: any[];

    constructor() {
        super();

        this.wishlistData = document.querySelector('[data-wishlist-items]') as HTMLElement;
        const wishlistItems = this.wishlistData?.dataset.wishlistItems;
        this.wishlistItems = wishlistItems ? wishlistItems.split(',') : [];

        this.addEventListener('click', this.onClick);
        this.update();
    }

    /**
     * get pid
     */
    get pid() {
        return this.getAttribute('pid');
    }

    /**
     * get quantity
     */
    get quantity() {
        return document.querySelector('gep-atc-qty-stepper')?.querySelector('gep-quantity-stepper')?.value ?? '1';
    }

    attributeChangedCallback(_: any, oldValue: any, newValue: any) {
        if (newValue !== oldValue) {
            this.update();
        }
    }

    /**
     * update
     */
    update() {
        if (this.wishlistItems.includes(this.pid)) {
            this.classList.add('wishlist--added');
        } else {
            this.classList.remove('wishlist--added');
        }
    }

    /**
     * onClick
     * @param {MouseEvent} event - click event
     */
    onClick(event: MouseEvent) {
        event.stopPropagation();

        const url = getURL('wishlist-add-product');
        const data = {
            pid: this.pid,
            quantity: this.quantity
        };

        showSpinner();

        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data,
            success: (response: any) => {
                hideSpinner();

                if (response.success) {
                    this.classList.add('wishlist--added');
                    this.wishlistItems.push(this.pid);
                    this.wishlistData.dataset.wishlistItems = this.wishlistItems.join(',');
                } else {
                    $.notification({
                        time: $.NOTIFICATION_TIME.SHORT,
                        type: $.NOTIFICATION_TYPE.INFO,
                        message: response.msg
                    }).show();
                }
            },
            error: (err: JQueryXHR) => {
                hideSpinner();
                if (handleServerErrors(err)) return;

                $.notification({
                    time: $.NOTIFICATION_TIME.SHORT,
                    type: $.NOTIFICATION_TYPE.DANGER,
                    message: err.responseJSON.errorMessage
                }).show();
            }
        });
    }
}

window.customElements.define('gep-add-to-wishlist', AddToWishlist);

declare global {
    interface HTMLElementTagNameMap {
        'gep-add-to-wishlist': AddToWishlist
    }
}
