/*
 * This module contains the least code possible to support loading the
 * minicart code when needed, rather than on every page view.
 *
 * - If there is anything in the cart, then hovering over the minicart
 *   icon loads the code and contents.
 * - Receiving a minicart:update event updates the quantity, affecting
 *   the above test.
 */

import { breakpointSize, breakpointUp } from 'core/core/utils/css';

/**
 * Update the minicart quantity when other code updates the cart.
 */
function updateQuantity(event: CustomEvent<{ quantityTotal: number }>) {
    const quantity = event.detail.quantityTotal || 0;
    const minicartQuantity = document.querySelector('.minicart-quantity');
    if (minicartQuantity) {
        minicartQuantity.textContent = String(quantity);
    }
}

/**
 * Load the minicart the first time that the mouse hovers over the icon.
 */
async function loadMinicart(this: HTMLElement) {
    // If quantity is zero, don't load anything.
    const quantity = this.querySelector('.minicart-quantity')?.textContent || '0';
    if (quantity === '0') {
        return;
    }

    // If screen is mobile-sized, don't do anything.
    if (!breakpointUp(breakpointSize.LG).matches) {
        return;
    }

    // Get the MiniCart-Show URL if it is present.
    const url = this.dataset.href;
    if (!url) {
        return;
    }

    // Stop listening to mouse events on current minicart icon.
    this.removeEventListener('mouseenter', loadMinicart);

    // Start loading the minicart content, which may take the longest.
    const xhr = $.ajax(url);

    // Load the minicart code and replace the minicart placeholder.
    const { default: MinicartContainer } = await import('core/containers/miniCart');
    const container = new MinicartContainer();
    container.className = this.className;
    container.url = url;
    const minicart = document.createElement('gep-minicart');
    minicart.quantity = Number(quantity);
    container.append(minicart, ...Array.from(this.children).slice(1));
    this.replaceWith(container);

    // Stop listening for quantity updates.
    window.removeEventListener('minicart:update', updateQuantity);

    // Update the minicart with the MiniCart-Show response once it has finished rendering.
    container.updateMiniCart = false;
    await minicart.updateComplete;
    container.request(xhr);
}

function initMinicartLoader() {
    const minicart = document.querySelector<HTMLElement>('div.minicart');
    if (minicart) {
        minicart.addEventListener('mouseenter', loadMinicart);
        window.addEventListener('minicart:update', updateQuantity);
    }
}

initMinicartLoader();
