import { handleServerErrors } from 'core/core/components/request';
import { addToCart, removeLineItem, updateQuantity } from 'core/requests/cart';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export enum AddToCartMode {
    BUTTON = 'add-to-cart:button',
    QUANTITY = 'add-to-cart:quantity'
}

export enum AddToCartVariant {
    CONTAINER = 'container',
    OUTLINE = 'outline'
}

/**
 * It uses {light-dom}
 */
@customElement('gep-container-add-to-cart')
export default class AddToCartContainer extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    @property({ type: String })
    mode: AddToCartMode = AddToCartMode.BUTTON;

    @property({ type: Number })
    quantity = 1;

    @property({ type: Number })
    max = 999;

    @property({ type: String })
    pid: string;

    @property({ type: Boolean })
    spinner = false;

    @property({ type: String })
    variant: AddToCartVariant = AddToCartVariant.CONTAINER;

    @property({ type: String, reflect: true })
    context: string;

    @property({ type: Boolean, reflect: true })
    disabled = false;

    uuid: string;

    constructor() {
        super();
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    toggle(value: boolean): void {
        if (this) this.classList.toggle('disabled', !value);
    }

    onClickAddToCart() {
        if (this.mode === AddToCartMode.BUTTON) this.mode = AddToCartMode.QUANTITY;
        this.spinner = true;

        addToCart({ pid: this.pid, quantity: 1, context: this.context })
            .then((response) => {
                this.spinner = false;
                if (response.error) {
                    $.notification({
                        type: $.NOTIFICATION_TYPE.DANGER,
                        time: $.NOTIFICATION_TIME.SHORT,
                        message: response.message
                    }).show();

                    if (this.mode === AddToCartMode.BUTTON) this.mode = AddToCartMode.BUTTON;

                    return;
                }

                window.dispatchEvent(new CustomEvent('minicart:update', { detail: { quantityTotal: response.quantityTotal } }));

                const pli = response.cart.items.find((item: any) => item.id === this.pid);
                if (pli) {
                    this.uuid = pli.UUID;
                    this.quantity = pli.quantity;
                    this.max = pli.maxProductQuantity || 999;
                }
            })
            .catch((err: JQueryXHR) => {
                this.spinner = false;
                if (handleServerErrors(err)) return;
                $.notification({
                    type: $.NOTIFICATION_TYPE.DANGER,
                    time: $.NOTIFICATION_TIME.SHORT,
                    message: err.responseJSON ? err.responseJSON.errorMessage : $.NOTIFICATION_GLOBAL_MESSAGE.ERROR
                }).show();
            });
    }

    onChangeQuantity(event: CustomEvent) {
        const quantity = Number(event.detail.quantity);

        // Update quantity
        if (quantity > 0) {
            this.spinner = true;
            updateQuantity(this.pid, quantity, this.uuid)
                .then((response) => {
                    this.spinner = false;
                    window.dispatchEvent(new CustomEvent('minicart:update', { detail: { quantityTotal: response.quantityTotal } }));
                })
                .catch((err) => {
                    this.spinner = false;
                    if (handleServerErrors(err)) return;
                    $.notification({
                        type: $.NOTIFICATION_TYPE.DANGER,
                        time: $.NOTIFICATION_TIME.SHORT,
                        message: err.responseJSON ? err.responseJSON.errorMessage : $.NOTIFICATION_GLOBAL_MESSAGE.ERROR
                    }).show();
                });
        } else {
            this.spinner = true;

            // Remove from cart

            removeLineItem(this.pid, this.uuid, this.context)
                .then((response) => {
                    this.mode = AddToCartMode.BUTTON;
                    this.spinner = false;
                    if (response.errorMessage) {
                        $.notification({
                            type: $.NOTIFICATION_TYPE.DANGER,
                            time: $.NOTIFICATION_TIME.SHORT,
                            message: response.errorMessage
                        }).show();
                        return;
                    }

                    window.dispatchEvent(new CustomEvent('minicart:update', { detail: { quantityTotal: response.basket.quantityTotal } }));
                })
                .catch((err) => {
                    this.spinner = false;
                    if (handleServerErrors(err)) return;
                    $.notification({
                        type: $.NOTIFICATION_TYPE.DANGER,
                        time: $.NOTIFICATION_TIME.SHORT,
                        message: err.responseJSON ? err.responseJSON.errorMessage : $.NOTIFICATION_GLOBAL_MESSAGE.ERROR
                    }).show();
                });

        }
    }

    render() {
        if (this.mode === AddToCartMode.QUANTITY) {
            return html`
                <gep-quantity-stepper
                    @quantity-stepper:changed="${this.onChangeQuantity}"
                    ?spinner="${this.spinner}"
                    value="${this.quantity}"
                    max="${this.max}"
                    min="0"
                    ?disabled="${this.disabled}"
                ></gep-quantity-stepper>
            `;
        }

        return html`
            <button
                class="btn btn-${this.variant} btn-block ${this.disabled ? 'disabled' : ''}"
                @click="${this.onClickAddToCart}"
            >
                Add to cart
            </button>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-container-add-to-cart': AddToCartContainer
    }
}
