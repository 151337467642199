import { debounce, DebounceFunction } from "framework/core/utils/functions";
import { IColorSwatchEvent, ColorSwatchEventType } from "../../events/colorSwatch";

/**
 * Swatch Color
 */
export class ColorSwatch extends HTMLElement {

    static readonly BUTTON_MARGIN_RIGHT = 12;
    static readonly BUTTON_WIDTH = 48;
    static readonly EXPANDED_CLASS = 'expanded';

    $value: JQuery;
    $inputEls: JQuery;
    $toggleBtn: JQuery;
    $groupToggle: JQuery;
    isExpanded: boolean;
    onResizeDebounced: DebounceFunction;

    connectedCallback() {
        this.$value = $(this).find('.swatch-color-header-value');
        this.$inputEls = $(this).find('input');
        this.$toggleBtn = $(this).find('.swatch-color-toggle-btn');
        this.$groupToggle = $(this).find('.swatch-color-group-toggle');
        this.isExpanded = $(this).hasClass(ColorSwatch.EXPANDED_CLASS);

        this.onChange = this.onChange.bind(this);
        this.onClickToggle = this.onClickToggle.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onResizeDebounced = debounce(this.onResize, 50);

        this.$inputEls.on('change', this.onChange);
        this.$toggleBtn.on('click', this.onClickToggle);
        window.addEventListener('resize', this.onResizeDebounced);

        $(this).addClass('ready');
        this.updateLabel();
        setTimeout(() => this.update(), 350);
    }

    disconnectedCallback() {
        window.removeEventListener('resize', this.onResizeDebounced);
        this.$inputEls.off('change', this.onChange);
        this.$toggleBtn.off('click', this.onClickToggle);
    }

    /**
     * onChange
     * @param {JQuery.ChangeEvent} event - change event
     */
    onChange(event: JQuery.ChangeEvent) {
        this.updateLabel();

        const target = event.currentTarget as HTMLInputElement;

        const options = {
            bubbles: true,
            composed: true,
            detail: {
                attributeURL: String(target.dataset.url),
                attributeId: String(target.dataset.attrId),
                attributeValue: String(target.dataset.attrVariant)
            }
        };

        this.dispatchEvent(new CustomEvent<IColorSwatchEvent>(ColorSwatchEventType.CHANGE, options));
    }

    /**
     * onClickToggle
     */
    onClickToggle() {
        if (this.isExpanded) {
            this.prependActiveItem();
            this.collapse();
        } else {
            this.expand();
        }
    }

    /**
     * onResize
     */
    onResize() {
        this.update();
    }

    /**
     * update label
     */
    updateLabel() {
        const $inputChecked = $(this).find('input:checked');
        const title = $inputChecked.attr('title') ?? '';
        this.$value.text(title);
    }

    /**
     * toggleItems
     * @param {number} total number os items to show
     */
    toggleItems(total: number) {
        $(this).find('.swatch-color-btn').each((i, button) => {
            if (i < total) $(button).show();
            else $(button).hide();
        });
    }

    /**
     * Get total items to show
     * @return {number} total number os items to show
     */
    getTotalItemsToShow(): number {
        return Math.floor(this.clientWidth / (ColorSwatch.BUTTON_WIDTH + ColorSwatch.BUTTON_MARGIN_RIGHT));
    }

    /**
     * Prepend active item
     */
    prependActiveItem() {
        const $activeButton = $(this).find('input:checked').closest('.swatch-color-btn');
        if ($activeButton.index() >= this.getTotalItemsToShow()) {
            $activeButton.prependTo(this.$groupToggle);
            this.toggleItems(this.getTotalItemsToShow());
        }
    }

    /**
     * Expand
     */
    expand() {
        this.isExpanded = true;
        $(this).addClass(ColorSwatch.EXPANDED_CLASS);
        this.toggleItems($(this).find('.swatch-color-btn').length);
    }

    /**
     * Collapse
     */
    collapse() {
        this.isExpanded = false;
        $(this).removeClass(ColorSwatch.EXPANDED_CLASS);
        this.toggleItems(this.getTotalItemsToShow());
    }

    /**
     * update
     */
    update() {
        if (this.getTotalItemsToShow() < $(this).find('.swatch-color-btn').length) {
            this.$toggleBtn.show();
        } else {
            this.$toggleBtn.hide();
        }

        if (!this.isExpanded) {
            this.toggleItems(this.getTotalItemsToShow());
            this.prependActiveItem();
        }
    }
}

window.customElements.define('gep-swatch-color', ColorSwatch);

declare global {
    interface HTMLElementTagNameMap {
        'gep-swatch-color': ColorSwatch
    }
}

